<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="3">
        <v-text-field
          v-model="dateStartDoc"
          type="date"
          label="Desde"
          @change="openDir(idDirectoryOpened)"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field
          v-model="dateEndDoc"
          type="date"
          label="Hasta"
          @change="openDir(idDirectoryOpened)"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          v-model="searchDoc"
          label="Buscar un documento"
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="2">
        <v-menu>
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              :disabled="selectedClient == null"
              text
              stacked
              density="comfortable"
              v-if="showCreate"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-btn
              text
              stacked
              density="comfortable"
              style="margin-left: 5px"
              @click="paste"
              v-if="clipboardDir != null || clipboardDoc != null"
            >
              <v-icon>mdi-content-paste</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in itemsToCreate"
              :key="index"
              :value="index"
              @click="create(item)"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="12"
        class="d-flex align-center"
        style="font-size: 0.8em"
      >
        <v-icon
          class="ma-2"
          style="cursor: pointer"
          @click="openDir(0, 1)"
          color="primary"
          >mdi-folder</v-icon
        >
        <span> / </span>
        <template v-for="(crumb, index) in breadcrumbs" :key="index">
          <span
            style="margin-left: 5px; margin-right: 5px; cursor: pointer"
            @click="openDir(crumb.id, 0)"
          >
            {{ crumb.name }}
          </span>
          <span v-if="index < breadcrumbs.length - 1"> / </span>
        </template>
      </v-col>
    </v-row>
    <v-list two-line subheader style="margin-top: 2em">
      <v-list-item v-for="dir in filteredItemsDir" :key="dir.id">
        <div class="list-item-content">
          <div
            class="list-item-top"
            style="cursor: pointer"
            @click="openDir(dir.id, 0)"
          >
            <v-avatar>
              <v-icon :class="[dir.iconClass]">{{ dir.icon }}</v-icon>
            </v-avatar>
            <div class="list-item-text">
              <v-list-item-title class="text-left">{{
                dir.title
              }}</v-list-item-title>
              <v-list-item-subtitle class="text-left">{{
                dir.subtitle
              }}</v-list-item-subtitle>
            </div>
          </div>
          <div class="list-item-actions">
            <v-list-item-action>
              <v-btn variant="plain" @click="editDir(dir)"
                ><v-tooltip activator="parent" location="bottom"
                  >Editar</v-tooltip
                ><v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-action>
              <v-btn variant="plain" @click="cutDir(dir.id)"
                ><v-tooltip activator="parent" location="bottom"
                  >Cortar</v-tooltip
                ><v-icon>mdi-content-cut</v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-action>
              <v-btn
                variant="plain"
                @click="deleteDir(dir.id)"
                v-if="showDelete"
                ><v-tooltip activator="parent" location="bottom"
                  >Eliminar</v-tooltip
                ><v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-list-item-action>
          </div>
        </div>
      </v-list-item>
      <v-list-item v-for="item in filteredItemsDoc" :key="item.id">
        <div class="list-item-content">
          <div class="list-item-top">
            <v-avatar>
              <v-icon :class="[item.iconClass]">{{ item.icon }}</v-icon>
            </v-avatar>
            <div class="list-item-text">
              <v-list-item-title class="text-left">{{
                item.title
              }}</v-list-item-title>
              <v-list-item-subtitle class="text-left">{{
                item.subtitle
              }}</v-list-item-subtitle>
            </div>
          </div>
          <div class="list-item-actions">
            <v-list-item-action
              v-if="
                item.type == 'application/pdf' || item.type?.includes('image/')
              "
            >
              <v-btn
                icon="mdi-file"
                variant="plain"
                @click="openDocument(item.id, item.type)"
                ><v-tooltip activator="parent" location="bottom"
                  >Abrir documento</v-tooltip
                ><v-icon>mdi-file</v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-action>
              <v-btn variant="plain" @click="editDoc(item)"
                ><v-tooltip activator="parent" location="bottom"
                  >Editar</v-tooltip
                ><v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-action>
              <v-btn variant="plain" @click="cutDoc(item.id)" alt="Cortar"
                ><v-tooltip activator="parent" location="bottom"
                  >Cortar</v-tooltip
                ><v-icon>mdi-content-cut</v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-action>
              <v-btn
                icon="mdi-download"
                variant="plain"
                @click="downloadDocument(item.id, item.title, item.type)"
                ><v-tooltip activator="parent" location="bottom"
                  >Descargar</v-tooltip
                ><v-icon>mdi-download</v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-action>
              <v-btn
                variant="plain"
                @click="deleteDocument(item.id)"
                v-if="showDelete"
                ><v-tooltip activator="parent" location="bottom"
                  >Eliminar</v-tooltip
                ><v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-list-item-action>
          </div>
        </div>
      </v-list-item>
    </v-list>
    <ShowDialog ref="dialogRef" />
    <ShowMessage ref="messageRef" />
  </v-container>
</template>

<script>
import { ref, onMounted, computed, watch } from "vue";
import { VTooltip } from "vuetify/lib/directives";
import { BASE_URL } from "@/components/constants.js";
import * as basics from "@/components/basics.js";
import genericReload from "../components/genericReload";
import { useAppStore } from "@/store/app";

export default {
  props: {
    selectedClient: Number,
  },
  directives: {
    tooltip: VTooltip,
  },
  setup(props) {
    const store = useAppStore();

    const dialogRef = ref(null);
    const messageRef = ref(null);

    const dateStartDoc = ref(basics.getDate("year", -1));
    const dateEndDoc = ref(basics.getDate("date", 1));
    const searchDoc = ref("");
    const searchDocuments = ref([]);
    const searchDirectories = ref([]);
    const directoryOpened = ref("");
    const idDirectoryOpened = ref(0);
    const clipboardDir = ref(null);
    const clipboardDoc = ref(null);

    const itemsToCreate = ref([
      { title: "Nuevo documento" },
      { title: "Nuevo directorio" },
    ]);
    const documents = ref([]);
    const directories = ref([]);

    const showCreate = ref(false);
    const showDelete = ref(false);

    const breadcrumbs = ref([]);

    onMounted(() => {
      showCreate.value = store.canCreate;
      showDelete.value = store.canDelete;
      openDir(0);
      searchDirectories.value = directories.value;
      searchDocuments.value = documents.value;
      updateBreadcrumbs();
    });

    const filteredItemsDoc = computed(() => {
      return searchDocuments.value.filter(
        (item) =>
          item.title.match(searchDoc.value) ||
          item.title.toLowerCase().match(searchDoc.value) ||
          item.subtitle.match(searchDoc.value) ||
          item.subtitle.toLowerCase().match(searchDoc.value)
      );
    });
    const filteredItemsDir = computed(() => {
      return searchDirectories.value.filter(
        (dir) =>
          dir.title.match(searchDoc.value) ||
          dir.title.toLowerCase().match(searchDoc.value)
      );
    });

    const updateBreadcrumbs = () => {
      let i = 0;
      let can = 1;
      while (breadcrumbs.value[i]) {
        if (breadcrumbs.value[i].id == idDirectoryOpened.value) {
          can = 0;
        }
        i++;
      }
      if (can) {
        const currentDirId = idDirectoryOpened.value;
        const currentDirName = directoryOpened.value;
        if (currentDirId) {
          breadcrumbs.value.push({ id: currentDirId, name: currentDirName });
        }
      } else {
        const indexToRemove = breadcrumbs.value.findIndex(
          (item) => item.id === idDirectoryOpened.value
        );
        breadcrumbs.value.splice(indexToRemove + 1);
      }
    };

    const create = async (item) => {
      if (props.selectedClient) {
        if (item.title == "Nuevo documento") {
          dialogRef.value
            .setNew("Subir nuevo documento", "", "newDoc")
            .then(async (confirm) => {
              if (confirm.response) {
                let email = "";
                if(confirm.notification != ""){
                  const responseClient = await genericReload(store.auth, "api/client/" + props.selectedClient);
                  if (responseClient.status === 200) {
                    const jsonClient = await responseClient.json();
                    if (jsonClient[0]) {
                      email = jsonClient[0].email;
                    }
                  }
                }
                
                for (let i = 0; i < confirm.file.length; i++) {
                  const f = confirm.file[i];
                  let formData = new FormData();
                  formData.append("idCliente", props.selectedClient);
                  formData.append("file", f);
                  formData.append("nombre", confirm.name);
                  formData.append("permisos", confirm.permissions);
                  if(i == 0 && confirm.notificar){
                    formData.append("email", email);
                    formData.append("notificacion", confirm.notification);
                  }
                  formData.append("directorio", idDirectoryOpened.value);
                  const headers = {
                    Authorization: "Bearer " + store.auth,
                  };

                  const response = await fetch(`${BASE_URL}/api/document`, {
                    method: "POST",
                    headers: headers,
                    body: formData,
                  });

                  switch (response.status) {
                    case 200:
                      messageRef.value.setNew(
                        true,
                        "Subida correcta. Fichero: "+f["name"],
                        "Ok",
                        "green"
                      );
                      openDir();
                      break;
                    default:
                      messageRef.value.setNew(
                        true,
                        basics.returnError(response.status),
                        "Cerrar",
                        "red"
                      );
                      break;
                  }
                }
                
              } else {
                messageRef.value.setNew(
                  true,
                  "El documento NO se ha subido",
                  "Cerrar",
                  "red"
                );
              }
            });
        } else {
          if (item.title == "Nuevo directorio") {
            dialogRef.value
              .setNew("Crear nuevo directorio", "", "newDir")
              .then(async (confirm) => {
                if (confirm.response) {
                  const newDirectory = {
                    idCliente: props.selectedClient,
                    nombre: confirm.name,
                    permisos: confirm.permissions,
                    directorio: idDirectoryOpened.value,
                  };
                  const headers = {
                    Authorization: "Bearer " + store.auth,
                    "Content-Type": "application/json",
                  };

                  const response = await fetch(`${BASE_URL}/api/directory`, {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify(newDirectory),
                  });

                  switch (response.status) {
                    case 200:
                      messageRef.value.setNew(
                        true,
                        "Directorio creado correctamente",
                        "Ok",
                        "green"
                      );
                      openDir();
                      break;
                    default:
                      messageRef.value.setNew(
                        true,
                        basics.returnError(response.status),
                        "Cerrar",
                        "red"
                      );
                      break;
                  }
                } else {
                  messageRef.value.setNew(
                    true,
                    "El directorio NO se ha creado",
                    "Cerrar",
                    "red"
                  );
                }
              });
          }
        }
      }
    };

    const bytesToSize = (bytes) => {
      let sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    };

    const getIconType = (mimetype) => {
      const [type, subtype] = mimetype.split("/");
      switch (subtype) {
        case "pdf":
          return { icon: "mdi-file-pdf", iconClass: "red" };
        case "msword":
          return { icon: "mdi-file-word", iconClass: "blue" };
        case "vnd.openxmlformats-officedocument.wordprocessingml.document":
          return { icon: "mdi-file-word", iconClass: "blue" };
        case "msexcel":
          return { icon: "mdi-file-excel", iconClass: "green" };
        case "vnd.ms-excel":
          return { icon: "mdi-file-excel", iconClass: "green" };
        case "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          return { icon: "mdi-file-excel", iconClass: "green" };
        case "vnd.ms-powerpoint":
          return { icon: "mdi-file-powerpoint", iconClass: "orange" };
        case "vnd.openxmlformats-officedocument.presentationml.presentation":
          return { icon: "mdi-file-powerpoint", iconClass: "orange" };
      }
      switch (type) {
        case "image":
          return { icon: "mdi-file-image", iconClass: "yellow" };
        case "audio":
          return { icon: "mdi-book-music", iconClass: "red" };
        case "video":
          return { icon: "mdi-file-video", iconClass: "purple" };
        case "text":
          return { icon: "mdi-file-document-outline", iconClass: "grey" };
        case "dir":
          return { icon: "mdi-folder", iconClass: "grey" };
      }
    };

    const getDocuments = async (id) => {
      documents.value = [];
      searchDocuments.value = documents.value;
      if (props.selectedClient) {
        const response = await genericReload(
          store.auth,
          "api/document/data/?idCliente=" +
            props.selectedClient +
            "&fechaInicio=" +
            dateStartDoc.value +
            "&fechaFin=" +
            dateEndDoc.value +
            "&tipoUsuario=" +
            store.typeUser +
            "&directorio=" +
            id
        );
        if (response.status === 200) {
          const json = await response.json();
          for (let i = 0; i < json.length; i++) {
            const type = getIconType(json[i].type);
            const fecha = basics.formatDate(
              json[i].fecha.split(" ")[0],
              "dd/mm/yyyy"
            );
            const hora = json[i].fecha.split(" ")[1];
            documents.value.push({
              id: json[i].id,
              type: json[i].type,
              path: json[i].path,
              icon: type.icon,
              iconClass: type.iconClass + " white--text",
              title: json[i].nombre,
              subtitle:
                "Fecha subida: " +
                fecha +
                " " +
                hora +
                " | Tamaño: " +
                bytesToSize(json[i].size),
            });
          }
          searchDocuments.value = documents.value;
        }
      }
    };

    const openDocument = (id, type) => {
      let xhr = new XMLHttpRequest();
      xhr.open("GET", BASE_URL + "/api/document/" + id, true);
      xhr.setRequestHeader("Authorization", "Bearer " + store.auth);
      xhr.responseType = "blob";
      xhr.onload = function () {
        //let blob = xhr.response;
        let blob = new Blob([xhr.response], { type: type });
        let fileURL = URL.createObjectURL(blob);
        window.open(fileURL, "_blank");
      };
      xhr.onerror = function () {
        switch (xhr.status) {
          case 400:
            messageRef.value.setNew(
              true,
              "Error en la aplicación. Consulte a soporte técnico.",
              "Cerrar",
              "red"
            );
            break;
          case 401:
            messageRef.value.setNew(
              true,
              "Datos de inicio de sesión erróneos.",
              "Cerrar",
              "red"
            );
            break;
          case 500:
            messageRef.value.setNew(
              true,
              "Error en el servidor. Consulte a soporte técnico.",
              "Cerrar",
              "red"
            );
            break;
        }
      };
      xhr.send();
    };

    const downloadDocument = (id, name, type) => {
      let xhr = new XMLHttpRequest();
      xhr.open("GET", BASE_URL + "/api/document/" + id, true);
      xhr.setRequestHeader("Authorization", "Bearer " + store.auth);
      xhr.responseType = "blob";
      xhr.onload = function () {
        //let blob = xhr.response;
        let blob = new Blob([xhr.response], { type: type });
        let fileURL = URL.createObjectURL(blob);
        let fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", name);
        document.body.appendChild(fileLink);
        fileLink.click();
      };
      xhr.onerror = function () {
        switch (xhr.status) {
          case 400:
            messageRef.value.setNew(
              true,
              "Error en la aplicación. Consulte a soporte técnico.",
              "Cerrar",
              "red"
            );
            break;
          case 401:
            messageRef.value.setNew(
              true,
              "Datos de inicio de sesión erróneos.",
              "Cerrar",
              "red"
            );
            break;
          case 500:
            messageRef.value.setNew(
              true,
              "Error en el servidor. Consulte a soporte técnico.",
              "Cerrar",
              "red"
            );
            break;
        }
      };
      xhr.send();
    };

    const deleteDocument = (id) => {
      dialogRef.value
        .setNew(
          "¿Estás seguro de llevar a cabo esta operación?",
          "Se eliminará el documento.",
          0
        )
        .then((confirm) => {
          if (confirm.response) {
            dialogRef.value
              .setNew(
                "Es necesaria una doble confirmación",
                "Está intentando llevar a cabo una operación en la que pueden perderse datos irrecuperables. ¿Estás seguro?",
                0
              )
              .then(async (confirm) => {
                if (confirm.response) {
                  const headers = {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + store.auth,
                  };

                  const response = await fetch(
                    `${BASE_URL}/api/document/${id}`,
                    {
                      method: "DELETE",
                      headers: headers,
                    }
                  );
                  switch (response.status) {
                    case 200:
                      messageRef.value.setNew(
                        true,
                        "Documento eliminado",
                        "Ok",
                        "green"
                      );
                      openDir();
                      break;
                    case 400:
                      messageRef.value.setNew(
                        true,
                        "Error en la aplicación. Consulte a soporte técnico.",
                        "Cerrar",
                        "red"
                      );
                      break;
                    case 401:
                      messageRef.value.setNew(
                        true,
                        "No tienes permiso para llevar a cabo esta operación.",
                        "Cerrar",
                        "red"
                      );
                      break;
                    case 500:
                      messageRef.value.setNew(
                        true,
                        "Error en el servidor. Consulte a soporte técnico.",
                        "Cerrar",
                        "red"
                      );
                      break;
                    default:
                      messageRef.value.setNew(
                        true,
                        basics.returnError(response.status),
                        "Cerrar",
                        "red"
                      );
                      break;
                  }
                } else {
                  messageRef.value.setNew(
                    true,
                    "El documento NO se ha borrado",
                    "Cerrar",
                    "red"
                  );
                }
              });
          } else {
            messageRef.value.setNew(
              true,
              "El documento NO se ha borrado",
              "Cerrar",
              "red"
            );
          }
        });
    };

    const getDirectories = async (id) => {
      directories.value = [];
      searchDirectories.value = directories.value;
      if (props.selectedClient) {
        const response = await genericReload(
          store.auth,
          "api/directory/?idCliente=" +
            props.selectedClient +
            "&fechaInicio=" +
            dateStartDoc.value +
            "&fechaFin=" +
            dateEndDoc.value +
            "&directorio=" +
            id
        );
        if (response.status === 200) {
          const json = await response.json();
          for (let i = 0; i < json.length; i++) {
            const type = getIconType("dir");
            const fecha = basics.formatDate(
              json[i].fecha.split(" ")[0],
              "dd/mm/yyyy"
            );
            const hora = json[i].fecha.split(" ")[1];
            directories.value.push({
              id: json[i].id,
              icon: type.icon,
              iconClass: type.iconClass + " white--text",
              title: json[i].nombre,
              subtitle: "Fecha creación: " + fecha + " " + hora,
              parentId: json[i].directorio,
            });
          }
          searchDirectories.value = directories.value;
        }
      }
    };

    const openDir = async (id = idDirectoryOpened.value, clean = 0) => {
      if (clean) {
        breadcrumbs.value = [];
      }
      if (props.selectedClient) {
        directoryOpened.value = "";
        const response = await genericReload(store.auth, "api/directory/" + id);
        if (response.status === 200) {
          const json = await response.json();
          if (id && json[0]) {
            directoryOpened.value = json[0].nombre;
          }
          idDirectoryOpened.value = id;
          await getDirectories(id);
          await getDocuments(id);
          updateBreadcrumbs();
        }
      } else {
        directories.value = [];
        searchDirectories.value = directories.value;
        documents.value = [];
        searchDocuments.value = documents.value;
        breadcrumbs.value = [];
      }
    };
    const deleteDir = async (id) => {
      dialogRef.value
        .setNew(
          "¿Estás seguro de llevar a cabo esta operación?",
          "Se eliminará el directorio y su contenido.",
          0
        )
        .then(async (confirm) => {
          if (confirm.response) {
            dialogRef.value
              .setNew(
                "Es necesaria una doble confirmación",
                "Está intentando llevar a cabo una operación en la que pueden perderse datos irrecuperables. ¿Estás seguro?",
                0
              )
              .then(async (confirm) => {
                if (confirm.response) {
                  const headers = {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + store.auth,
                  };

                  const response = await fetch(
                    `${BASE_URL}/api/directory/${id}`,
                    {
                      method: "DELETE",
                      headers: headers,
                    }
                  );

                  switch (response.status) {
                    case 200:
                      messageRef.value.setNew(
                        true,
                        "Directorio eliminado",
                        "Ok",
                        "green"
                      );
                      openDir();
                      break;
                    case 400:
                      messageRef.value.setNew(
                        true,
                        "Error en la aplicación. Consulte a soporte técnico.",
                        "Cerrar",
                        "red"
                      );
                      break;
                    case 401:
                      messageRef.value.setNew(
                        true,
                        "No tienes permiso para llevar a cabo esta operación.",
                        "Cerrar",
                        "red"
                      );
                      break;
                    case 500:
                      messageRef.value.setNew(
                        true,
                        "Error en el servidor. Consulte a soporte técnico.",
                        "Cerrar",
                        "red"
                      );
                      break;
                    default:
                      messageRef.value.setNew(
                        true,
                        basics.returnError(response.status),
                        "Cerrar",
                        "red"
                      );
                      break;
                  }
                } else {
                  messageRef.value.setNew(
                    true,
                    "El directorio NO se ha borrado",
                    "Cerrar",
                    "red"
                  );
                }
              });
          } else {
            messageRef.value.setNew(
              true,
              "El directorio NO se ha borrado",
              "Cerrar",
              "red"
            );
          }
        });
    };

    const cutDoc = (id) => {
      clipboardDir.value = null;
      clipboardDoc.value = id;
      messageRef.value.setNew(
        true,
        "Documento en el portapapeles, ahora lo puedes pegar en el directorio deseado",
        "Ok",
        "green"
      );
    };

    const cutDir = (id) => {
      clipboardDoc.value = null;
      clipboardDir.value = id;
      messageRef.value.setNew(
        true,
        "Directorio en el portapapeles, ahora lo puedes pegar dentro de otro directorio",
        "Ok",
        "green"
      );
    };

    const paste = async () => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + store.auth,
      };
      const newDirectory = {
        directorio: idDirectoryOpened.value,
      };
      if (idDirectoryOpened.value == clipboardDir.value) {
        messageRef.value.setNew(
          true,
          "Error. No puedes mover un directorio dentro de si mismo.",
          "Cerrar",
          "red"
        );
      } else {
        let endpoint = null;
        if (clipboardDir.value != null) {
          const indexOpened = breadcrumbs.value.findIndex(
            (item) => item.id === idDirectoryOpened.value
          );
          const indexClipboard = breadcrumbs.value.findIndex(
            (item) => item.id === clipboardDir.value
          );
          if (indexOpened <= indexClipboard || indexClipboard < 0) {
            endpoint = `${BASE_URL}/api/directory/${clipboardDir.value}`;
          } else {
            endpoint = null;
            messageRef.value.setNew(
              true,
              "Error. No puedes mover un directorio dentro de si mismo.",
              "Cerrar",
              "red"
            );
          }
        }
        if (clipboardDoc.value != null) {
          endpoint = `${BASE_URL}/api/document/${clipboardDoc.value}`;
        }
        if (endpoint != null) {
          const response = await fetch(endpoint, {
            method: "PUT",
            headers: headers,
            body: JSON.stringify(newDirectory),
          });

          switch (response.status) {
            case 200:
              messageRef.value.setNew(
                true,
                "Movido correctamente",
                "Ok",
                "green"
              );
              openDir();
              break;
            case 400:
              messageRef.value.setNew(
                true,
                "Error en la aplicación. Consulte a soporte técnico.",
                "Cerrar",
                "red"
              );
              break;
            case 401:
              messageRef.value.setNew(
                true,
                "No tienes permiso para llevar a cabo esta operación.",
                "Cerrar",
                "red"
              );
              break;
            case 500:
              messageRef.value.setNew(
                true,
                "Error en el servidor. Consulte a soporte técnico.",
                "Cerrar",
                "red"
              );
              break;
            default:
              messageRef.value.setNew(
                true,
                basics.returnError(response.status),
                "Cerrar",
                "red"
              );
              break;
          }
          clipboardDir.value = null;
          clipboardDoc.value = null;
        }
      }
    };

    const editDoc = async (item) => {
      dialogRef.value
        .setNew("Editar documento", "", "newDir", item)
        .then(async (confirm) => {
          if (confirm.response) {
            let editDoc = null;
            if (confirm.permissions.length) {
              editDoc = {
                nombre: confirm.name,
                permisos: confirm.permissions,
              };
            } else {
              editDoc = {
                nombre: confirm.name,
              };
            }
            const headers = {
              Authorization: "Bearer " + store.auth,
              "Content-Type": "application/json",
            };

            const response = await fetch(
              `${BASE_URL}/api/document/${item.id}`,
              {
                method: "PUT",
                headers: headers,
                body: JSON.stringify(editDoc),
              }
            );

            switch (response.status) {
              case 200:
                messageRef.value.setNew(
                  true,
                  "Documento modificado correctamente",
                  "Ok",
                  "green"
                );
                openDir();
                break;
              default:
                messageRef.value.setNew(
                  true,
                  basics.returnError(response.status),
                  "Cerrar",
                  "red"
                );
                break;
            }
          } else {
            messageRef.value.setNew(
              true,
              "El documento NO se ha modificado",
              "Cerrar",
              "red"
            );
          }
        });
    };
    const editDir = async (dir) => {
      dialogRef.value
        .setNew("Editar directorio", "", "newDir", dir)
        .then(async (confirm) => {
          if (confirm.response) {
            let editDirectory = null;
            if (confirm.permissions.length) {
              editDirectory = {
                nombre: confirm.name,
                permisos: confirm.permissions,
              };
            } else {
              editDirectory = {
                nombre: confirm.name,
              };
            }
            const headers = {
              Authorization: "Bearer " + store.auth,
              "Content-Type": "application/json",
            };

            const response = await fetch(
              `${BASE_URL}/api/directory/${dir.id}`,
              {
                method: "PUT",
                headers: headers,
                body: JSON.stringify(editDirectory),
              }
            );

            switch (response.status) {
              case 200:
                messageRef.value.setNew(
                  true,
                  "Directorio modificado correctamente",
                  "Ok",
                  "green"
                );
                openDir();
                break;
              default:
                messageRef.value.setNew(
                  true,
                  basics.returnError(response.status),
                  "Cerrar",
                  "red"
                );
                break;
            }
          } else {
            messageRef.value.setNew(
              true,
              "El directorio NO se ha modificado",
              "Cerrar",
              "red"
            );
          }
        });
    };

    watch(
      () => props.selectedClient,
      (newSelectedClient) => {
        clipboardDir.value = null;
        clipboardDoc.value = null;
        openDir(0);
      }
    );

    return {
      showCreate,
      showDelete,
      messageRef,
      dialogRef,
      dateStartDoc,
      dateEndDoc,
      searchDoc,
      searchDocuments,
      searchDirectories,
      filteredItemsDoc,
      filteredItemsDir,
      itemsToCreate,
      directoryOpened,
      idDirectoryOpened,
      clipboardDir,
      clipboardDoc,
      breadcrumbs,
      updateBreadcrumbs,
      create,
      bytesToSize,
      getIconType,
      getDirectories,
      getDocuments,
      openDocument,
      downloadDocument,
      deleteDocument,
      openDir,
      deleteDir,
      cutDir,
      cutDoc,
      paste,
      editDoc,
      editDir,
    };
  },
};
</script>
<style scoped>
.list-item-content {
  display: flex;
  @media (max-width: 600px) {
    flex-direction: column;
  }
  margin-bottom: 16px;
}

.list-item-top {
  display: flex;
  align-items: center;
}

.list-item-actions {
  display: flex;
  @media (max-width: 600px) {
    justify-content: center;
    margin-top: 8px;
  }
}

@media (min-width: 601px) {
  .list-item-actions {
    margin-left: auto;
  }
}

.list-item-text {
  flex-grow: 1;
  padding-right: 16px;
  @media (max-width: 600px) {
    padding-right: 0;
  }
}

.text-left {
  text-align: left;
}
</style>
