import { defineStore } from 'pinia'
import genericReload from "../components/genericReload";

export const useAppStore = defineStore('app', {
  state: () => ({
    auth: 0,
    user: null,
    typeUser: null,
    canCreate: true,
    canDelete: true,
    clients: null
  }),
  getters: {
    clientById: (state) => (id) => {
      return state.clients.find(client => client.id === id);
    },
  },
  actions: {
    onStateChanged() {
      localStorage.setItem('docStoreData', JSON.stringify(this.$state));
    },
    async getClients(){
      const responseClient = await genericReload(this.$state.auth, "api/client");
      if (responseClient.status === 200) {
        this.$state.clients = await responseClient.json();
      }
    }
  }
})
