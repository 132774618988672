import { createApp } from "vue";
import { createPinia } from 'pinia'
import { useAppStore } from '@/store/app';
import App from "./App.vue";
import router from "./router";

// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

// Mdi
import "@mdi/font/css/materialdesignicons.css";

//Components
import ShowMessage from "@/components/Messages.vue";
import ShowDialog from "@/components/Dialog.vue";
import ShowDocuments from "@/components/Documents.vue";
import ShowCalendar from "@/components/Calendar.vue";
import ShowClientCalendar from "@/components/ClientCalendar.vue";

//VCalendar
import VCalendar from 'v-calendar';
import 'v-calendar/style.css';

const pinia = createPinia()

const color = process.env.VUE_APP_COLOR;

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        dark: false,
        variables: {},

        colors: {
          background: "#F8FCEC",
          surface: "#FFFFFF",
          primary: color,
          "primary-darken-1": "#FFA000",
          secondary: "#FF5722",
          "secondary-darken-1": "#E64A19",
          error: "#E91E63",
          info: "#2196F3",
          success: "#4CAF50",
          warning: "#FFEB3B",
        },
      },
      dark: {
        dark: true,
        variables: {},

        colors: {
          background: "#121212",
          surface: "#1E1E1E",
          primary: color,
          "primary-darken-1": "#FFA000",
          secondary: "#FF5722",
          "secondary-darken-1": "#E64A19",
          error: "#E91E63",
          info: "#2196F3",
          success: "#4CAF50",
          warning: "#FFEB3B",
        },
      },
    },
  },
});

const app = createApp(App).use(VCalendar, {}).use(pinia).use(router).use(vuetify);

app
  .component('ShowMessage', ShowMessage)
  .component('ShowDialog', ShowDialog)
  .component('ShowDocuments', ShowDocuments)
  .component('ShowCalendar', ShowCalendar)
  .component('ShowClientCalendar', ShowClientCalendar)

const savedData = localStorage.getItem('docStoreData');
if (savedData) {
  useAppStore().$state = JSON.parse(savedData);
}

app.mount("#app");

useAppStore().$subscribe(useAppStore().onStateChanged);