<template>
  <v-snackbar v-model="show" :multi-line="multiLine" :timeout="timeout">
    {{ text }}

    <v-btn variant="text" :color="colorBtn" @click="closeSnackbar">
      {{ textBtn }}
    </v-btn>
  </v-snackbar>
</template>

<script setup>
import { ref, defineExpose } from "vue";

const multiLine = true;
const timeout = 6000;
const show = ref(false);
const text = ref("");
const colorBtn = ref("red");
const textBtn = ref("Cerrar");

const closeSnackbar = () => {
  show.value = false;
};

const setNew = (newShow, newText, newTextBtn, newColorBtn) => {
  show.value = newShow;
  text.value = newText;
  textBtn.value = newTextBtn;
  colorBtn.value = newColorBtn;
};
defineExpose({ setNew });
</script>
