<template>
  <v-row>
    <v-col cols="12" sm="6">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-title color="primary">
            Nueva cita
            <template v-slot:actions="{ expanded }">
              <v-icon :icon="expanded ? 'mdi-pencil' : 'mdi-plus'"></v-icon>
            </template>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <v-row justify="center">
              <v-col cols="12">
                <div class="text-center">
                  <VDatePicker
                    :min-date="new Date()"
                    expanded
                    v-model="date"
                    mode="date"
                    timezone="Europe/Amsterdam"
                    :disabled-dates="disabledDates"
                  />
                  <VDatePicker
                    style="margin-top: 10px; margin-bottom: 10px"
                    v-model="date"
                    mode="time"
                    timezone="Europe/Amsterdam"
                  />
                  <v-text-field
                    v-model="text"
                    type="text"
                    label="Texto asociado"
                  ></v-text-field>
                  <v-btn
                    color="green"
                    variant="text"
                    @click="saveNewAppointment"
                    >Guardar</v-btn
                  >
                </div>
              </v-col>
            </v-row>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>

    <v-col cols="12" sm="6">
      <div class="today-section">
        <h3>Mis citas</h3>
        <v-list lines="one" v-if="items.length">
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
            :title="item.title"
          >
            <div v-html="item.subtitle" class="subtitle-with-line-breaks"></div>
            <template v-slot:append>
              <v-btn
                icon="mdi-delete"
                variant="text"
                @click="deleteAppointment(item.id)"
              ></v-btn>
            </template>
          </v-list-item>
        </v-list>
        <span v-else>No hay registros de citas en tu cuenta.</span>
      </div>
    </v-col>
  </v-row>
</template>

<script setup>
import { ref, onMounted, toRaw } from "vue";
import { useAppStore } from "@/store/app";
import genericReload from "./genericReload";
import * as basics from "./basics.js";
import { BASE_URL } from "./constants.js";

const store = useAppStore();

const attributes = ref([]);
const disabledDates = ref([
  {
    repeat: {
      weekdays: [7,1],
    },
  },
]);
const items = ref([]);
const date = ref(new Date());
const clientToAppointment = ref(null);
const text = ref("");

const getAppointments = async () => {
  items.value = [];
  const response = await genericReload(
    store.auth,
    "api/appointment?fechaInicio=" +
      basics.getDate("month", -2) +
      "&idCliente=" +
      store.user
  );
  if (response.status === 200) {
    const data = await response.json();
    for (let i = 0; i < data.length; i++) {
      const e = data[i];
      const fecha = basics.formatDate(
        data[i].fecha.split(" ")[0],
        "dd/mm/yyyy"
      );
      const hora = data[i].fecha.split(" ")[1];
      items.value.push({
        id: data[i].id,
        title: fecha + " " + hora.slice(0, 5),
        subtitle:
          e.texto +
          (e.estado ? " \n Estado: Aceptada" : " \n Estado: En espera"),
      });
    }
  }
};

const saveNewAppointment = async () => {
  const postData = {
    idCliente: store.user,
    fecha: date.value,
    texto: text.value,
    estado: 0,
  };
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + store.auth,
  };

  const response = await fetch(`${BASE_URL}/api/appointment`, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(postData),
  });

  switch (response.status) {
    case 200:
      clientToAppointment.value = null;
      text.value = "";
      date.value = new Date();
      await getAppointments();
      break;
  }
};
const deleteAppointment = async (id) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + store.auth,
  };
  const response = await fetch(`${BASE_URL}/api/appointment/${id}`, {
    method: "DELETE",
    headers: headers,
  });
  switch (response.status) {
    case 200:
      await getAppointments();
      break;
  }
};

onMounted(() => {
  getAppointments();
});
</script>

<style scoped>
.subtitle-with-line-breaks {
  white-space: pre-line; /* Esto hace que los saltos de línea sean visibles */
  font-size: 0.9em;
  color: grey;
}
.calendar {
  flex: 1;
  margin-right: 20px;
}

.today-section {
  margin-left: 30px;
  flex: 1;
  max-width: 400px;
}

.vc-time-picker {
  width: 100% !important;
}

@media (max-width: 768px) {
  .today-section {
    max-width: none;
    margin-left: 0px;
    margin-top: 30px;
  }
}

/* Para ajustar el ancho del panel de expansión en pantallas grandes */
@media (min-width: 768px) {
  .v-expansion-panel {
    max-width: 400px; /* Cambia el ancho según sea necesario */
  }
}

/* Para ajustar el ancho de los elementos internos (date pickers, autocomplete, etc.) en pantallas grandes */
@media (min-width: 768px) {
  .v-expansion-panel-text {
    max-width: 100%; /* Cambia el ancho según sea necesario */
  }
}
</style>
