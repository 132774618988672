<template>
  <v-dialog v-model="dialog" persistent>
    <v-card class="container">
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>
        <div v-if="show === 'newDoc'">
          <v-form ref="formNewDoc">
            <v-container>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-file-input
                    v-model="fileUploaded"
                    clearable
                    :multiple=true
                    accept="image/*,audio/*,video/*,text/*,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx"
                    label="Haz clic para seleccionar el fichero a subir"
                  ></v-file-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-text-field
                    label="Nombre"
                    v-model="nombreNewDoc"
                    placeholder="Nombre del documento"
                    required
                    :disabled="nameDisabled"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select
                    v-model="permisosNewDoc"
                    :items="permisos"
                    item-title="name"
                    item-value="value"
                    label="Permisos para"
                    multiple
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="4" v-if="store.typeUser=='admin'">
                  <v-checkbox
                    v-model="notify"
                    label="Avisar por email al cliente"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row v-if="notify">
                <v-col cols="12" sm="12">
                  <v-text-field
                    label="Texto del aviso"
                    v-model="notificationText"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </div>
        <div v-if="show === 'newDir'">
          <v-form ref="formNewDir">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="Nombre"
                    v-model="nombreNewDir"
                    placeholder="Nombre a mostrar"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="permisosNewDir"
                    :items="permisos"
                    item-title="name"
                    item-value="value"
                    label="Permisos para"
                    multiple
                    required
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </div>
        <div v-if="show === 'calendar'">
          <ShowCalendar />
        </div>
        <div v-if="show === 'appointment'">
          <ShowClientCalendar />
        </div>
        <div v-else-if="show === 'preFormatted'">
          <pre>{{ text }}</pre>
        </div>
        <div v-else>{{ text }}</div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="show !== 'calendar' && show !== 'appointment'" color="red" text @click="cancel"
          >Cancelar</v-btn
        >
        <v-btn v-if="show !== 'calendar' && show !== 'appointment'" color="green" text @click="agree"
          >Aceptar</v-btn
        >
        <v-btn v-if="show === 'calendar' || show === 'appointment'" color="red" text @click="cancel"
          >Cerrar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, defineExpose, watch } from "vue";
import { useAppStore } from "@/store/app";

const store = useAppStore();

const data = ref(undefined);
const dialog = ref(false);
const title = ref("");
const text = ref("");
const show = ref(0);
let resolve = null;
let reject = null;

const formNewDir = ref(null);
const formNewDoc = ref(null);
const nombreNewDir = ref("");
const nombreNewDoc = ref("");
const permisosNewDir = ref("");
const permisosNewDoc = ref("");
const permisos = ref([{name: "Administrador", value: "admin"}, {name: "Usuarios", value: "user"}]);
const fileUploaded = ref(undefined);
const nameDisabled = ref(false);
const notify = ref(false);
const notificationText = ref("");

const setNew = (newTitle, newText, newShow = 0, newData) => {
  dialog.value = true;
  title.value = newTitle;
  text.value = newText;
  show.value = newShow;
  data.value = newData;

  if (formNewDoc.value) {
    formNewDoc.value.reset();
  }
  if (formNewDir.value) {
    formNewDir.value.reset();
  }
  
  if(data.value && data.value.id && newShow == "newDir"){
    nombreNewDir.value = data.value.title;
  }

  return new Promise((_resolve, _reject) => {
    resolve = _resolve;
    reject = _reject;
  });
};

const agree = () => {
  switch (show.value) {
    case "newDoc":
      resolve({
        response: true,
        file: Array.from(fileUploaded.value),
        name: nombreNewDoc.value,
        permissions: permisosNewDoc.value,
        notification: notificationText.value,
        notificar: notify.value,
      });
      formNewDoc.value?.reset();
      break;
    case "newDir":
      resolve({
        response: true,
        name: nombreNewDir.value,
        permissions: permisosNewDir.value,
      });
      formNewDir.value?.reset();
      break;


    default:
      resolve({ response: true });
      break;
  }
  dialog.value = false;
};

const cancel = () => {
  formNewDoc.value?.reset();
  formNewDir.value?.reset();
  resolve(false);
  dialog.value = false;
};

watch(fileUploaded, (newValue) => {
  nombreNewDoc.value = "";
  if(fileUploaded.value){
    if(fileUploaded.value.length>1){
      nameDisabled.value = true;
    }else{
      if (newValue && newValue[0] instanceof File) {
        nameDisabled.value = false;
        nombreNewDoc.value = newValue[0].name;
      }
    }
  }
});

defineExpose({ setNew });
</script>

<style scoped>
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: inherit;
}
</style>
