<template>
  <div class="iconTheme" @click="toggleTheme">
    <v-icon>{{ icon }}</v-icon>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import { useTheme } from 'vuetify'

export default {
  setup() {
    const theme = useTheme()
    const isDarkTheme = ref(localStorage.getItem('isDarkTheme') === 'true')
    let icon = isDarkTheme.value ? 'mdi-weather-sunny' : 'mdi-weather-night'
    theme.global.name.value = isDarkTheme.value ? 'dark' : 'light'

    const toggleTheme = () => {
      isDarkTheme.value = !isDarkTheme.value
      theme.global.name.value = isDarkTheme.value ? 'dark' : 'light'
      localStorage.setItem('isDarkTheme', isDarkTheme.value.toString())
      updateIcon()
    }

    const updateIcon = () => {
      icon = computed(() => (isDarkTheme.value ? 'mdi-weather-sunny' : 'mdi-weather-night'))
    }

    updateIcon()

    return {
      icon,
      toggleTheme,
    }
  }
}
</script>

<style>
.iconTheme {
  cursor: pointer;
}
</style>
